import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { Button, DropZone as Drop } from "vardogyir-ui";
import Chip from "@material-ui/core/Chip";
import * as XLSX from "xlsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import config from "../../config";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import GetAppIcon from '@material-ui/icons/GetApp';

import Amplify, { Auth, Hub } from "aws-amplify";
import {
  Authenticator,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
} from "aws-amplify-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CustomSignIn} from "../CustomSignIn";
import UserAccount from "../component/UserAccount";

import "./BulkAssessment.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)(change to 'localhost' to work on local)
      domain: window.location.host.includes("localhost")
        ? "localhost"
        : config.DOMAIN,
      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).      
      secure: true,
      sameSite: "none"
    },
  },
});

const styles = (theme) => ({
  mainWrap: {
    display: "flex",
    justifyContent: "center",
    minHeight: '100vh', // Ensure the container takes up at least the full viewport height
  },
  backgroundContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  row: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    minWidth: "420px",
    justifyContent: "center",
  },
  wrap: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: 24,
    marginBottom: "16px",
    background: "#fff",
    borderRadius: "8px",
  },
  dropzone: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  dialogTitle: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  dialogContent: {
    padding: theme.spacing(2, 3),
    textAlign: 'left',
  },
  dialogActions: {
    padding: theme.spacing(1, 3, 2),
  },
  dialogIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  dialogImage: {
    width: '100%',
    maxHeight: 200,
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  iconSuccess: {
    display: "inline-block",
    float: "left",
    marginRight: "16px",
    color: "#31a633",
  },
  dialogPaper: {
    padding: "24px",
  },
  dialogPaperExcel: {
    padding: "0px 24px",
  },
  dialogImageSize: {
    height: "auto",
    width: "auto",
    maxHeight: "256px",
    margin: "auto",
    display: "block",
    objectFit: "contain",
  },
  popupImageStyle: {
    width: "100%",
    height: "256px",
    marginTop: "8px",
    marginBottom: "16px",
  },
  iconDiscard: {
    display: "inline-block",
    float: "left",
    marginRight: "16px",
    color: "#B00020",
  },
  errorUpload: {
    fontSize: "12px",
    color: "#B00020",
  },
});

// Function for rendering the excel files
const ExcelRenderer = (file) => {

  // Only allow .xlsx or .csv
  let allowedExtensions = /(\.xlsx|\.csv)$/i;
  if (!allowedExtensions.exec(file.path)) return "Incorrect format";

  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    var rABS = !!reader.readAsBinaryString;

    reader.onload = function (e) {
      /* Parse data */
      const data = e.target.result;

      if (file.path.endsWith('.csv')) {
        // Parse CSV data
        const csvData = data.split('\n').map((row) => row.split(','));
        resolve(csvData);
      } else {
        // Parse XLSX data
        const wb = XLSX.read(data, { type: "binary" });

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const json = XLSX.utils.sheet_to_json(ws, { header: 1 });
        resolve(json);
      }
    };

    if (file.path.endsWith('.csv')) reader.readAsText(file);
    else if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

class BulkAssessment extends React.Component {
  state = {
    data: [],
    name: null,
    key: null,
    file: null,
    componentId: null,
    titleData: [],
    openValidate: false,
    errorUpload: false,
    errorUploadMessage: "",
    disabledSubmit: true,
    saved: false,
    authState: "loading",
    authData: null,
    authError: null,
    missingColumn: [],
    bulk_oauth2: null,
    pageProcessing: false
  };

  async componentDidMount() {
    let componentId = this.props.match.params.component_id;
    const isTest = this.props.location.search === "?test=true";

    let token = await this.processIdToken();

    let url = `${config.API_ADDRESS}/bulk/${componentId}`;

    if (isTest) {
      url += "?isLatestDraft=true"
    }

    let json = await axios
      .get(url, { headers: { Authorization: token } })
      .catch((e) => {
        console.log("Error during call to Bulk Service " + e);
      });

    if (json && json.status == 200 && json.data && json.data.components && json.data.components.length > 0) {
      let { name, data } = json.data.components[0].componentData;
      let { fields, key } = data;
      let titleData = fields.map((field) => {
        return field.keyName ? field.keyName.replace(/[\n\r\t\b\f]/g, "") : ""; // Remove char literals
      });

      let bulk_oauth2 = json.data.bulk_oauth2 ? json.data.bulk_oauth2 : null;
      this.setState({
        name,
        key,
        titleData,
        componentId: componentId,
        bulk_oauth2: bulk_oauth2,
      });
    } else {
      this.props.history.push("/reload_error");
    }

    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          this.setState({
            authState: "signedIn",
            authData: data.payload.data,
          });
          break;
        case "signIn_failure":
          this.setState({
            authState: "signIn",
            authData: null,
            authError: data.payload.data,
          });
          break;
        default:
          break;
      }
    });

    Auth.currentSession()
      .then((user) => {
        if (user != null) this.setState({ authState: "signedIn" });
      })
      .catch((err) => console.log(err));
  }

  async processIdToken() {
    try {
      var data = await Auth.currentSession();

      return data.idToken.jwtToken;
    } catch (err) {
      console.log(err);
    }
  }

  onFilesDrop = async (files) => {
    if (files.length === 0) return;

    const file = files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension !== 'csv' && fileExtension !== 'xlsx') {
      this.setState({
        errorUpload: true,
        errorUploadMessage: "Only CSV and XLSX files are allowed.",
        openValidate: true,
      });
      return;
    }

    let resp = await ExcelRenderer(file);
    if (typeof resp === "string") {
      this.setState({
        errorUploadMessage: resp,
        openValidate: true
      })
    } else {
      let { titleData } = this.state;
      let missingColumn = [];
      let titleExcel = [];
      if (resp.length > 0) {
        titleExcel = resp[0].map((title) => {
          // Strip character literals and convert to lowercase
          return title.toString().toLowerCase().replace(/[^a-z0-9_]/g, "");
        });
      }
      for (let i = 0; i < titleData.length; i++) {
        let requiredColumn = titleData[i].toLowerCase().replace(/[^a-z0-9_]/g, "");
        if (!titleExcel.includes(requiredColumn)) {
          missingColumn.push(titleData[i]);
        }
      }
      if (missingColumn.length > 0) {
        this.setState({ openValidate: true, missingColumn, errorUploadMessage: "Missing columns" })
      } else {
        let data = this.handelExcelToJson(resp);

        this.setState({
          file: file,
          data,
          errorUpload: false,
          disabledSubmit: false,
        });
      }
    }
  };

  handelExcelToJson = (data) => {
    let fields = [];
    let titles = data[0];
    for (let i = 1; i < data.length; i++) {
      let obj = {};
      let currentline = data[i];
      let status = 0;
      for (let j = 0; j < titles.length; j++) {
        if (currentline[j]) status = 1;

        let keyName = titles[j].toString().toLowerCase().split(" ").join("_");
        obj[keyName] = currentline[j];
      }

      if (status == 1) fields.push(obj);
    }
    return fields;
  };

  handleSubmit = async () => {
    this.setState({
      pageProcessing: true
    })
    let { data, componentId, key } = this.state;
    let requestData = {
      componentId: componentId,
      data: {
        key: key,
        uploads: data,
      },
    };
    let token = await this.processIdToken();
    let url = `${config.API_ADDRESS}/bulk_assessment_submit`;
    const isTest = this.props.location.search === "?test=true";

    if (isTest) {
      url += "?isTest=true";
      requestData["projectTestId"] = this.props.match.params.test_id;
    }

    let json = await axios
      .post(url, requestData, {
        headers: {
          Authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .catch((e) => {
        console.log("Error during call to Bulk Service " + e);
      });

    if (json.status == 200) {
      this.setState({
        data: [],
        file: null,
        openValidate: false,
        disabledSubmit: true,
        saved: true,
      });
      this.setState({
        pageProcessing: false
      })
    }
  };

  generateSampleCSV = () => {
    const { titleData } = this.state;
    const csvContent = titleData.join(',') + '\n';
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "sample_template.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  generateSampleXLSX = () => {
    const { titleData } = this.state;
    const worksheet = XLSX.utils.aoa_to_sheet([titleData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
    XLSX.writeFile(workbook, "sample_template.xlsx");
  };

  render() {
    const { classes } = this.props;
    const {
      file,
      name,
      titleData,
      disabledSubmit,
      saved,
      authState,
      bulk_oauth2,
    } = this.state;

    if (authState != "signedIn" && bulk_oauth2 != null) {
      return (
        <Authenticator hideDefault={true}>
          {/*<SignIn />*/}
          {/*<ConfirmSignIn />*/}
          {/*<ForgotPassword />*/}
          {/*<RequireNewPassword />*/}
          {/*<SignUp />*/}
          {/*<ConfirmSignUp />*/}
          <CustomSignIn
            authState={this.state.authState}
            updateUsername={this.updateUsername}
          />
        </Authenticator>
      );
    } else {
      return (
        <>
          <div className={classes.backgroundContainer}>
            <div className="app-background"></div>
          </div>
          <div className={classes.mainWrap}>
            <div className={classes.row}>
              <div className={classes.wrap}>
                <div className="header-container">
                  <h3 className="bulk-assessment-title">{name}</h3>
                  <div className="user-account-wrapper">
                    <UserAccount/>
                  </div>
                </div>
                <p className="upload-instructions">
                  Upload a spreadsheet in .xlsx or .csv format to trigger this workflow
                </p>
                <p className="column-instructions">
                  The uploaded spreadsheet should contain the following columns:{' '}
                  {titleData.length > 0 &&
                    titleData.map((title, index) => {
                      if (index == titleData.length - 1) {
                        return <span>{title} </span>
                      }
                      return <span> {title}, </span>
                    })
                  }
                </p>
                <div className="button-wrapper">
                  <Button
                    startIcon={<GetAppIcon />}
                    onClick={this.generateSampleCSV}
                    className="template-button csv-button"
                  >
                    Download CSV Template
                  </Button>
                  <Button
                    startIcon={<GetAppIcon />}
                    onClick={this.generateSampleXLSX}
                    className="template-button"
                  >
                    Download XLSX Template
                  </Button>
                </div>
                <div className={classes.dropzone}>
                  <Dropzone
                    style={{ width: "100%" }}
                    multiple={false}
                    onDrop={(file) => this.onFilesDrop(file)}
                    accept=".csv,.xlsx"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} style={{}}>
                          <Drop />
                          <input {...getInputProps()} />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div className="file-chip-wrapper">
                    {file && file.name && (
                      <Chip
                        className="file-chip"
                        label={<span className="file-chip-label"> {file.name} </span>}
                        onDelete={() =>
                          this.setState({ file: null, disabledSubmit: true })
                        }
                        color="primary"
                      />
                    )}
                    {this.state.errorUpload && (
                      <span className={classes.errorUpload}>
                        <ErrorIcon className="error-icon" />
                        Uploaded spreadsheet invalid: {" "}
                        {this.state.errorUploadMessage}
                      </span>
                    )}
                  </div>
                  <div>
                    <p className="submit-instructions">
                      Click submit to complete
                            </p>
                    <Button
                      theme={"P_blue"}
                      variant="contained"
                      onClick={this.handleSubmit}
                      disabled={disabledSubmit || this.state.pageProcessing}
                    >
                      Submit
                      {this.state.pageProcessing && <CircularProgress className="buttonProgress" size={20} />}
                    </Button>
                  </div>
                </div>
                <Dialog
                  fullWidth={true}
                  maxWidth={"sm"}
                  open={this.state.openValidate}
                  onClose={() => this.setState({ openValidate: false })}
                >
                  <DialogTitle className={classes.dialogTitle}>
                    <Typography variant="h6">
                      <ErrorIcon className={classes.dialogIcon} color="error" />
                      Invalid input
                    </Typography>
                  </DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                    <DialogContentText>
                      {this.state.missingColumn.length === 0
                        ? "Uploaded file is not a valid csv or xlsx file."
                        : "The uploaded spreadsheet does not contain the required columns of data. Please ensure that the following columns are in the spreadsheet and spelled correctly, and then re-upload the spreadsheet to try again:"}
                    </DialogContentText>
                    {this.state.missingColumn.length > 0 && (
                      <Typography variant="body2" className="missing-columns">
                        {this.state.missingColumn.map((title, index) => (
                          <span key={index}>
                            {index > 0 && ", "}
                            <code>{title}</code>
                          </span>
                        ))}
                      </Typography>
                    )}
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <Button
                      onClick={() => {
                        this.setState({
                          openValidate: false,
                          missingColumn: [],
                          errorUpload: true,
                        });
                      }}
                      color="primary"
                    >
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  fullWidth={true}
                  maxWidth={"sm"}
                  open={saved}
                  onClose={() => this.setState({ saved: false })}
                >
                  <DialogTitle className={classes.dialogTitle}>
                    <Typography variant="h6">
                      <CheckCircleIcon className={classes.dialogIcon} color="primary" />
                      Bulk assessment started
                    </Typography>
                  </DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                    <DialogContentText>
                      A bulk assessment has been successfully started. A workflow session will be started for each valid row of data and any invalid rows will be skipped.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <Button
                      onClick={() => this.setState({ saved: false })}
                      color="primary"
                    >
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default withStyles(styles)(BulkAssessment);
