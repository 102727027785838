import React, { Component } from "react";
import { Auth } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import { Button } from "vardogyir-ui";
import {Button as ButtonUI, CircularProgress} from '@material-ui/core';
// import FacebookIcon from '@material-ui/icons/Facebook';
import "./customLogin.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import GoogleIcon from "./component/Icon/GoogleIcon";
import axios from "axios";
import config from "../config";

// import { send_request } from "../../utils/Request";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class CustomSignIn extends Component {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      username: "",
      resetUserName: "",
      password: "",
      errorMessage: "",
      openError: false,
      formState: "signIn",
      newPassword: "",
      user: {},
      pageProcessing: false,
      loading: false
    };
  }

  componentDidMount = () => {
    this.setState({
      username: "",
      password: "",
      formState: "signIn",
      resetUserName: "",
      newPassword: "",
    });

    Auth.currentSession()
      .then(userSession => {
        if (userSession.getRefreshToken()){

          this.props.onStateChange("signedIn", {});
          //update last logged
          this.updateLastLogged();
        }
      })
      .catch((err) => {
        console.log(err)
        console.log("Not signed in")
      });
  };

  handleResetPassword = () => {
    this.setState({ formState: "resetPassword" });
  };

  handleSetNewPassword = () => {
    this.setState({ formState: "setNewPassword" });
  };

  handleFirstTimeSignIn = () => {
    this.setState({ formState: "changePassword" });
  };

  handleFormSubmission = (event) => {
    event.preventDefault();
    if (this.state.formState === "signIn") {
      this.signIn();
    } else if (this.state.formState === "resetPassword") {
      this.resetPassword();
    } else if (this.state.formState === "setNewPassword") {
      this.setNewPassword();
    } else if (this.state.formState === "changePassword") {
      this.changePassword();
    }
  };

  resendCode = () => {
    this.resetPassword();
  }

  resetPassword = () => {
    const resetUserName = this.state.resetUserName;
    Auth.forgotPassword(resetUserName)
      .then(() => {
        this.setState({ formState: "setNewPassword" });
      })
      .catch(error => {
        this.setState({ openError: true });
        this.setState({ errorMessage: error.message });
      });
  };

  setNewPassword = () => {
    const resetUserName = this.state.resetUserName;
    const code = this.state.code;
    const newPassword = this.state.newPassword;
    Auth.forgotPasswordSubmit(resetUserName, code, newPassword)
        .then(() => {
              Auth.signIn(resetUserName, newPassword).then(r => {
                Auth.signOut({global: true}).then(r => {
                  console.log("Initiating global logout");
                  this.setState({
                    formState: "signIn",
                    resetUserName: "",
                    newPassword: ""
                  });
                  window.location.href = "/";
                })
              })
            }
        )
        .catch((error) => {
          this.setState({ openError: true });
          this.setState({ errorMessage: error.message });
        });
  };

  changePassword = () => {
    Auth.completeNewPassword(
      this.state.user,               // the Cognito User Object
      this.state.newChangePassword,       // the new password
    ).then(user => {
      this.updateLastLogged();
      // at this time the user is logged in if no MFA required
      console.log(user);
      this.props.onStateChange("signedIn", {});
    }).catch(error => {
      this.setState({ openError: true });
      this.setState({ errorMessage: error.message });
    });
  };

  signIn = () => {
    this.setState({
      formState: "signIn",
      resetUserName: "",
      newPassword: "",
      pageProcessing: true
    });
    const username = this.state.username;
    const password = this.state.password;
    Auth.signIn(username, password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam;
          this.setState({
            formState: "changePassword",
            user: user,
            pageProcessing: false
          });
        } else {
          // other situations
          this.props.onStateChange("signedIn", {});
          this.setState({
            pageProcessing: false
          });
          this.updateLastLogged();
        }

        //update last logged 

      }).catch(error => {
        console.log(error);
        this.setState({ openError: true });
        this.setState({ errorMessage: error.message });
      });
  }

  updateLastLogged = async () => {
     const json = await this.send_request(`update-last-logged`, "", {});
  }
   serializeData = (formBodyObj) => {
    const requestBody = Object.keys(formBodyObj)
        .map((key) => {
          return (
              encodeURIComponent(key) + "=" + encodeURIComponent(formBodyObj[key])
          );
        })
        .join("&");

    return requestBody;
  }
 send_request = async(
      path,
      bodydata,
      querydata,
      method,
      header = { Accept: "application/json", "Content-Type": "application/json" },
      responseType,
      onUploadProgress = null
  ) => {
    let url = `${config.USER_ADDRESS}/${path}`;

    if (querydata) {
      url = url + "?" + this.serializeData(querydata);
    }
    try {
      header["Authorization"] = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
      const request = await axios(url, {
        method: method,
        data: bodydata,
        headers: header,
        responseType: responseType,
        onUploadProgress,
        timeout: 28000
      })
          .then(
              (res) => {
                return res;
              },
              (rej) => {
                throw rej;
                // return rej;
              }
          )
          .catch(async (error) => {
            throw error;
          });
      return request;
    } catch (err) {
      if (err === "No current user" || (err.code && err.code === "NotAuthorizedException"))//In case no user found exception is thrown
      {
        await Auth.signOut().then(r => window.location.pathname = "/");
      }
      return null;
    }
  }
  handleClose = () => {
    this.setState({ openError: false });
  };

  backToSignIn = () => {
    this.setState({ formState: "signIn" });
  };

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.state[name] = check_type ? checked : value;
    this.state["checkedValue"] = check_type ? value : null;
  };

  signInWithGG = async () => {
    this.setState({loading: true})
    const formUrl = window.location.pathname;
    await Auth.federatedSignIn({provider: 'Google', customState: formUrl}).then(async (user) => {
      setTimeout(() => {
        this.setState({loading: false})
      },20000)
    })
  }

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.openError}
          autoHideDuration={6000}
          onClose={this.handleClose}
          key={"top" + "center"}
        >
          <Alert onClose={this.handleClose} severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
        <Grid container spacing={2} className="main-page">
          <Grid item xs={4} style={{ background: "url(/images/background.jpg) 50% / cover" }} />
          <Grid item xs={12} sm={8} className="main-form-login">
            {this.state.formState === "signIn" ? (
              <div className="mainDiv">
                {this._validAuthStates.includes(this.props.authState) && (
                  <form onSubmit={this.handleFormSubmission} >
                    <Typography component="div">
                      <div><img src="/svgs/logo.svg" alt="logo" /></div>
                      <h4>Sign in to your account</h4>
                    </Typography>
                    <Typography component="div" className="login-with-app-another">
                      <div>
                        <div className="sign-in-with-gg-text">
                          Created an account using Google?
                        </div>
                        <ButtonUI
                          variant="contained"
                          color="secondary"
                          className="sign-in-with-gg"
                          style={{marginBottom: 16,background: "#DD4B39", color: "#ffffff"}}
                          startIcon={<GoogleIcon/>}
                          onClick={this.signInWithGG}
                          disabled={this.state.loading}
                        >
                          SIGN IN WITH GOOGLE
                          {this.state.loading && (
                            <CircularProgress
                              size={24}
                              className="buttonProgress"
                            />
                          )}
                        </ButtonUI>
                      </div>
                      {/*<div> <ButtonUI variant="contained" color="primary" startIcon={<FacebookIcon />} > SIGN IN WITH FACEBOOK </ButtonUI> </div>*/}
                    </Typography>
                    <div className="inputArea">
                      <p className="inputDescription">Username</p>
                      <TextField
                        id="username"
                        key="username"
                        name="username"
                        style={{ width: "100%" }}
                        placeholder="Enter your username or email"
                        variant="outlined"
                        type="text"
                        onChange={this.handleInputChange}
                      />
                      <p className="inputDescription">Password</p>
                      <TextField
                        style={{ width: "100%" }}
                        placeholder="Enter your password"
                        variant="outlined"
                        id="password"
                        key="password"
                        name="password"
                        type="password"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="actionArea">
                      <Typography>
                        <Link href="#" onClick={this.handleResetPassword}>
                          Forgot your password?
                        </Link>
                        {/* <Link href="#" onClick={this.handleResetPassword}>
                          I don’t have an account
                        </Link> */}
                      </Typography>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <br />
                        <Button
                          className="signInButton"
                          theme={"P_blue"}
                          type="submit"
                          onClick={this.handleFormSubmission}
                          disabled={this.state.pageProcessing}
                          id="sign_in_button"
                        >
                          SIGN IN
                          {this.state.pageProcessing && (
                            <CircularProgress
                              className="buttonProgress"
                              size={20}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            ) : (
                <div className="mainDiv">
                  {this._validAuthStates.includes(this.props.authState) && (
                    <form onSubmit={this.handleFormSubmission} >
                      {this.state.formState === "changePassword" ? (
                        <React.Fragment>
                          <Typography component="div">
                            <div><img src="/svgs/logo.svg" alt="logo" /></div>
                            <h4>Change Password</h4>
                          </Typography>
                          <div className="inputArea">
                            <p className="inputDescription">New Password</p>
                            <TextField
                              id="newChangePassword"
                              key="newChangePassword"
                              name="newChangePassword"
                              style={{ width: "100%" }}
                              placeholder="New Password"
                              variant="outlined"
                              type="password"
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="actionArea">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button style={{ marginRight: "10px" }} theme={"S_blue"} onClick={this.backToSignIn} > BACK </Button>
                              <Button theme={"P_blue"} type="submit" onClick={this.handleFormSubmission} > CHANGE </Button>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                      {this.state.formState === "resetPassword" ? (
                        <React.Fragment>
                          <Typography component="div">
                            <div><img src="/svgs/logo.svg" alt="logo" /></div>
                            <h4>Reset your Password</h4>
                          </Typography>
                          <div className="inputArea">
                            <p className="inputDescription">Username</p>
                            <TextField
                              id="resetUserName"
                              key="resetUserName"
                              name="resetUserName"
                              style={{ width: "100%" }}
                              placeholder="Enter your username"
                              variant="outlined"
                              type="text"
                              onChange={this.handleInputChange}
                            />
                            <p className="inputDescription">
                              We will send a password reset code to the email
                                connected to the user account. </p>
                          </div>
                          <div className="actionArea">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                style={{ marginRight: "10px" }}
                                className="backButton"
                                theme={"S_blue"}
                                onClick={this.backToSignIn}
                              >
                                BACK  </Button>
                              <Button
                                className="resetButton"
                                theme={"P_blue"}
                                type="submit"
                                onClick={this.handleFormSubmission}
                              >
                                SEND CODE </Button>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                      {this.state.formState === "setNewPassword" ? (
                        <React.Fragment>
                          <Typography component="div">
                            <div><img src="/svgs/logo.svg" alt="logo" /></div>
                            <h4>Reset your Password</h4>
                          </Typography>
                          <div className="inputArea">
                            <p className="inputDescription">Code</p>
                            <TextField
                              id="code"
                              key="code"
                              name="code"
                              style={{ width: "100%" }}
                              placeholder="Code"
                              variant="outlined"
                              type="text"
                              onChange={this.handleInputChange}
                            />
                            <p className="inputDescription">New Password</p>
                            <TextField
                              style={{ width: "100%" }}
                              placeholder="Enter your password"
                              variant="outlined"
                              id="newPassword"
                              key="newPassword"
                              name="newPassword"
                              type="password"
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="actionArea">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button style={{ marginRight: "10px" }} className="backButton" theme={"S_blue"} onClick={this.resendCode}  > RESEND CODE </Button>
                              <Button className="resetButton" theme={"P_blue"} type="submit" onClick={this.handleFormSubmission} > SUBMIT </Button>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </form>
                  )}
                </div>
              )}

          </Grid>
        </Grid>
      </div>
    );
  }
}
